import {trackPageView} from "./tracking/view_tracking";
import {extractMASTrackingData, makeContextFeatureId, makeListFeatureId, tracking} from "./tracking/shared";
import {VisibilityTracker} from "./tracking/visibility_tracker";
import {submitMiniActions} from "@otto-ec/nav_star-track/src/js/star-track";
import {initLinkTracking} from "./tracking/link_tracking";
import {logger} from "@otto-ec/global-resources/debug";
import { getVisitorId } from "./utils";

const log = logger("ft-nav.promirnaty.main");

const categorySuggestionsVisibilityTracker = (featureId) => new VisibilityTracker(
    () => submitMiniActions([{featureId: featureId, status: "visible", action: "scroll"}]));


export class CategorySuggestionsTiles extends HTMLElement {
    constructor() {
        super();
    }

    async personalise() {
        const customizertAbortController = new AbortController();
        const timeoutCustomizerAbort = setTimeout(() => customizertAbortController.abort("timeout"), 1000);
        const customizerResponse = await fetch('/nav-customizer/local-recos', {
            headers: {vid: getVisitorId()},
            signal: customizertAbortController.signal
        });
        clearTimeout(timeoutCustomizerAbort);
        if (customizerResponse.status === 200) {
            const customizerContent = await customizerResponse.json();
            if(customizerContent.localRecos && Array.isArray(customizerContent.localRecos) && customizerContent.localRecos.length > 0) {
                const promirnatyAbortController = new AbortController();
                const timeoutPromirnatyAbort = setTimeout(() => promirnatyAbortController.abort("timeout"), 1000);
                const personalisedContent = await fetch('/nav-promirnaty/category-suggestions/personalised-content?' + new URLSearchParams({
                    id: this.id,
                }).toString(), {
                    method: 'POST',
                    body: JSON.stringify(customizerContent),
                    signal: promirnatyAbortController.signal
                });
                clearTimeout(timeoutPromirnatyAbort);
                if (personalisedContent.status === 200) this.innerHTML = await personalisedContent.text();
            }
        }
    }

    async connectedCallback() {
        log.debug("Initializing category suggestions tiles", {id: this.id});

        try {
            await this.personalise();
        } finally { /*                         */
            /*                                                                            */
            const tilesContainer = this.querySelector(`.nav_category-suggestions-slider`);
            if (tilesContainer.closest('[data-feature-order]')) {
                const trackingContextEl = tilesContainer.querySelector(tracking.context);
                const masTrackingData = extractMASTrackingData(tilesContainer);
                trackingContextEl.dataset.masPosition = masTrackingData.featureOrder;
                trackingContextEl.dataset.masFeatureTrackingLabels = masTrackingData.featureTrackingLabels;

            }
            initLinkTracking(tilesContainer);
            trackPageView(tilesContainer, tilesContainer.dataset.sliderType, {});
            categorySuggestionsVisibilityTracker(makeContextFeatureId(this.id)).observe(tilesContainer);
            const tiles = Array.from(tilesContainer.querySelectorAll(".nav_tile"));
            tiles.map(tile => categorySuggestionsVisibilityTracker(makeListFeatureId(tile)).observe(tile));
        }
    }
}
