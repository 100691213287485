/**
 *
 */
export function isMobileBreakpoint() {
    let breakpoint = o_global.breakpoint.getCurrentBreakpoint();
    return (breakpoint === "s" || breakpoint === "m");
}

export function getVisitorId() {
    const cookie = document.cookie;

    if (!cookie) return null;

    const cookieValue = cookie.split("; ").find(row => row.startsWith("visitorId"));
    return cookieValue ? cookieValue.split("=")[1] : null;
}