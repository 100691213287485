import { getVisitorId } from "./utils";

export class BrandsBlock extends HTMLElement {

    constructor() {
        super();
    }

    async personalise(psr, featureId) {
        if (!psr) return;
        const encodedPsr = encodeURIComponent(psr)
        const customizertAbortController = new AbortController();
        const timeoutCustomizerAbort = setTimeout(() => customizertAbortController.abort("timeout"), 1000);
        const customizerResponse = await fetch('/nav-customizer/brands-block/' + encodedPsr, {
            headers: {vid: getVisitorId()},
            signal: customizertAbortController.signal
        });
        clearTimeout(timeoutCustomizerAbort);
        if (customizerResponse.status === 200) {
            const customizerContent = await customizerResponse.json();
            if(customizerContent.topBrands && Array.isArray(customizerContent.topBrands) && customizerContent.topBrands.length > 0) {
                const promirnatyAbortController = new AbortController();
                const timeoutPromirnatyAbort = setTimeout(() => promirnatyAbortController.abort("timeout"), 1000);
                const personalisedContent = 
                await fetch('/nav-promirnaty/brands-block/personalised-content/' + encodedPsr + '?' + new URLSearchParams({
                    id: featureId,
                }).toString(), {
                    method: 'POST',
                    body: JSON.stringify(customizerContent),
                    signal: promirnatyAbortController.signal
                });
                clearTimeout(timeoutPromirnatyAbort);
                if (personalisedContent.status === 200) this.innerHTML = await personalisedContent.text();
            }
        }
    }

    async connectedCallback() {
        try {
            const {psr, featureId} = this.dataset;
            await this.personalise(psr, featureId);
        } finally { 
            /*                             */
        }
    }

}